import React, { useState, useEffect } from "react";
import Api from "../../../helpers/Api";
import { useHistory, useParams } from "react-router-dom/";
import { useSelector, useDispatch } from "react-redux";
import ProfileIssueCRM from "../../issue/profile.issue.crm.view";
import ProfileIssueView from "../../issue/profile.issue.view";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import ProfileIssueEdit from "../../issue/profile.issue.edit";
import { Loadinglogo } from "../../../helpers/loadinglogo";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
}));

export default function BookingIssueView({ editModeValue }) {
  const history = useHistory();
  const classes = useStyles();
  const { issueId } = useParams();
  const {} = classes;

  const [issue, setIssue] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(editModeValue);
  useEffect(() => {
    setEditMode(editModeValue);
  }, [editModeValue]);

  const getData = async () => {
    try {
      const res = await Api.post("issue/getIssueDetail", {
        issue: issueId,
      });

      const data = res?.data;
      console.log(data, "issue/getIssueDetail ");
      if (data?._id) {
        setIssue(data);
      }

      setLoading(false);
    } catch (error) {
      console.log("error in profile.issue.view.manager : ", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      {loading ? (
        <Loadinglogo />
      ) : (
        <>
          {issue?.template?.isCRM ? (
            <ProfileIssueCRM issue={issue} setIssue={setIssue} />
          ) : editMode ? (
            <ProfileIssueEdit issue={issue} setIssue={setIssue} />
          ) : (
            <ProfileIssueView issue={issue} setIssue={setIssue} />
          )}
        </>
      )}
    </div>
  );
}
