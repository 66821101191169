import React, { useState, useEffect } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Button,
  Checkbox,
  Divider,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
  FormControlLabel,
  Typography,
  IconButton,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  RadioGroup,
  FormLabel,
  Radio,
  Autocomplete,
  Tooltip,
  InputAdornment,
  Grid,
  styled,
  Chip,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ProjectPicker from "../../../styled/generic/ProjectPicker";
import ProjectBlockAndUnitPickerDropdown from "../../../styled/generic/ProjectBlockAndUnitPickerDropdown";
import UserSearcher from "../../../styled/generic/UserSearcher";
import BankAccountPicker from "../../../styled/generic/BankAccountPickerDropdown";
import PaymentSection from "../PaymentSection";
import {
  Edit,
  Delete,
  Add,
  Percent,
  CurrencyRupee,
  People,
  Assignment,
  LocationOn,
  Home,
  Person,
  Description,
  Phone,
  Apartment,
  Business,
  House,
} from "@mui/icons-material";
import CreatePalDrawer from "../../../AddMember/CreatePalDrawer";
import SearchEntityDialog from "../../../styled/CommonComponents/SearchEntityDrawer";
import { useSelector } from "react-redux";
import FormBox from "../../../styled/generic/FormBox";
import { BiTrash } from "react-icons/bi";
import Api from "../../../../helpers/Api";
import { EditOutlined } from "@material-ui/icons";

export const CustomerProfile = ({
  formData,
  handleChange,
  handleRemoveEntity,
  givenProfileIdArr,
}) => {
  const { user } = useSelector((state) => state.auth);
  const [openCustomerPal, setOpenCustomerPal] = useState(false);
  const [showLeadsDrawer, setShowLeadsDrawer] = useState(false);
  const [mainProfileWitParent, setMainProfileWitParent] = useState({});

  const handleAddCustomer = (entity) => {
    handleChange("customerProfile", entity);
    setShowLeadsDrawer(false);
  };

  const handleEditCustomer = (newOne) => {
    handleChange("customerProfile", newOne);
    setOpenCustomerPal(false);
  };

  return (
    <Box>
      {formData.customerProfile ? (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={2}>
            <Avatar
              src={formData.customerProfile.parent?.displayPicture?.url}
              sx={{ width: 56, height: 56 }}
            />
            <Box>
              <Typography variant="subtitle1">
                {formData.customerProfile.parent?.displayName}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {formData.customerProfile.parent?.phone}
              </Typography>
            </Box>
          </Box>
          <Box>
            <IconButton
              onClick={() => {
                setMainProfileWitParent(formData.customerProfile);
                setOpenCustomerPal(true);
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              onClick={() =>
                handleRemoveEntity(
                  "customerProfile",
                  formData.customerProfile._id
                )
              }
            >
              <Delete />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Button
          variant="outlined"
          onClick={() => setShowLeadsDrawer(true)}
          startIcon={<Add />}
          fullWidth
        >
          Add Customer
        </Button>
      )}
      <SearchEntityDialog
        orgtype="User"
        givenFilterOption={[
          {
            option: "Contact",
            types: ["Customer", "Other"],
          },
        ]}
        entity="User"
        multiple={false}
        curEntityId={user?._id}
        givenProfileIdArr={givenProfileIdArr}
        onSave={handleAddCustomer}
        open={showLeadsDrawer}
        setOpen={setShowLeadsDrawer}
        title="Select Customer"
        financialRelationType="Customer"
      />
      <CreatePalDrawer
        openPal={openCustomerPal}
        setOpenPal={setOpenCustomerPal}
        fRelationId={mainProfileWitParent?.profile?.finrel}
        selectEntityFunction={handleEditCustomer}
        pal={mainProfileWitParent}
        title="Edit Customer"
        orgtype="User"
        financialRelationType="Customer"
        mainProfileWitParent={mainProfileWitParent}
        profileIdArr={[]}
        notAllowedRoles={[]}
        actionType="Update"
      />
    </Box>
  );
};
