import React, { useEffect, useState } from "react";
import StandardContainer from "../../styled/generic/StandardContainer";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import Api from "../../../helpers/Api";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import HorizBox from "../../styled/generic/HorizBox";
import SingleUserWithCaptionTableCell from "../../styled/generic/SingleUserWithCaptionTableCell";
import StatusChip from "../../styled/generic/StatusChip";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Button,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  DialogContentText,
} from "@mui/material";
import AddIcon from "@material-ui/icons/Add";
import TabsWithActions from "../../styled/generic/TabsWithActions";
import DemandDeedsList from "../demanddeed/DemandDeedsList";
import { EditOutlined, MoreVert } from "@material-ui/icons";
import { BiTrash } from "react-icons/bi";
import ConfirmationDialog from "../../global/ConfirmationDialog";
import { LoadingButton } from "@mui/lab";
import IssuesList from "./IssuesList";
import PaymentTable from "./PaymentTable";
import TransactionsList from "./TransactionsList";
import BookingBroker from "./BookingBroker";
import { PaymentTableView } from "./PaymentTableView";

const BookingView = () => {
  const history = useHistory();
  const { bookingId } = useParams();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [booking, setBooking] = useState();
  const [openConformationDialog, setOpenConformationDialog] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [template, setTemplate] = useState({});
  const [creatingTicket, setCreatingTicket] = useState(false);
  const [paymentComponents, setPaymentComponents] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const profileId = user.project;

  console.log("booking", booking);
  const getBooking = async () => {
    const { data } = await Api.post("/listing/booking/get-by-id", {
      bookingId: bookingId,
    });
    if (data) {
      setBooking(data);
      setTemplate(data?.team);
    } else {
      history.goBack();
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  useEffect(() => {
    getBooking().finally(() => {
      setPageLoading(false);
    });
  }, []);

  const getPaymentTableComponents = async () => {
    const data = await Api.post("listing/booking/payment/component/get", {
      tableId: booking?.paymentTable?._id,
    });
    if (data) {
      setPaymentComponents(data.data);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Something went wrong.",
        },
      });
    }
    setTableLoading(false);
  };

  useEffect(() => {
    if (booking?.paymentTable?._id) {
      getPaymentTableComponents();
    }
  }, [booking]);

  const handleDeleteBooking = async () => {
    const data = await Api.post("/listing/booking/delete", {
      bookingId: bookingId,
    });
    if (data) {
      history.goBack();
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Booking and associated objects deleted successfully",
        },
      });
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const createApi = async () => {
    setCreatingTicket(true);
    const issueShared = template?.issueShared || [];
    const pipeline = template?.pipeline;
    const userProfileId = user?.profile;
    const sharedSet = new Set([...issueShared, profileId, userProfileId]);

    console.log(template);
    let issueObject = {
      user: user._id,
      template: template?._id,
      profile: profileId,
      shared: Array.from(sharedSet),
      project: booking?.project,
      parent: booking._id,
      parentModelName: "Booking",
      shared: booking?.accountManagers?.map((item) => item._id),
      tags: [
        {
          data: booking._id,
          dataModel: "Booking",
        },
        {
          data: booking?.project,
          dataModel: "Project",
        },
        {
          data: booking?.library,
          dataModel: "BuildingCodeLibrary",
        },
      ].filter((item) => item.data),
    };

    if (pipeline?.length > 0) {
      issueObject = { ...issueObject, status: pipeline[0]?._id };
    }

    const res = await Api.post("issue/create", issueObject);
    if (res) {
      const data = res?.data;
      const issueId = data?._id;
      const path = "/issue/edit/" + issueId;
      history.push(path);
    }
  };

  const [tab, setTab] = useState("Table");
  if (pageLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="85vh"
        width="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <StandardContainer
      showAppBar={true}
      appBarTitle="Booking"
      appBarActions={
        <HorizBox>
          <LoadingButton
            variant="contained"
            startIcon={<AddIcon />}
            onClick={createApi}
            loading={creatingTicket}
          >
            Add Issue
          </LoadingButton>
        </HorizBox>
      }
    >
      <StandardAppContainerRounded>
        <HorizBox>
          <SingleUserWithCaptionTableCell
            url={booking?.customerProfile?.parent?.displayPicture?.url}
            label={booking?.customerProfile?.parent?.displayName}
          />
          <StatusChip label="Sale" variant="Warning" rounded={true} />
          <StatusChip
            label={booking?.bookingStatus}
            variant="Completed"
            rounded={true}
          />

          <StatusChip
            variant="Info"
            label={`Invite code: ${booking?.inviteCode}`}
            rounded={true}
          />
        </HorizBox>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={3}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Sanctioned/Unsanctioned
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">
              {booking?.sanctioned ? "Sanctioned" : "Unsanctioned"}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Booking Date
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">
              {new Date(booking?.bookingDate).toLocaleDateString()}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Booking Manager
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">
              {booking?.accountManagers[0]?.parent?.displayName ?? "NaN"}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Registration Date
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">
              {new Date(booking?.registrationDate).toLocaleDateString()}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Total value
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">
              ₹
              {booking?.paymentDetails?.reduce(
                (acc, curr) => acc + Number(curr.chargeAmount),
                0
              ) ?? 0}
            </Typography>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end" gap={"10px"}>
          {/* <Button variant="outlined" color="primary" onClick={()=> history.push("/booking")}>
            View More
          </Button> */}
          <Tooltip title="Edit booking" enterDelay={2000}>
            <IconButton
              onClick={() => {
                history.push(`/booking/edit/${bookingId}`);
              }}
              color="primary"
            >
              <EditOutlined />
            </IconButton>
          </Tooltip>
          {/* <IconButton
            onClick={() => {
              setOpenConformationDialog(true);
            }}
            color="primary"
          >
            <BiTrash />
          </IconButton> */}
          <StatusChanger
            currentStatus={booking.status}
            fetchBooking={getBooking}
          />
        </Box>
      </StandardAppContainerRounded>
      <StandardAppContainerRounded>
        <TabsWithActions
          onTabChange={(val) => setTab(val)}
          currentTabVariable={tab}
          tabs={[
            {
              title: "Table",
            },
            {
              title: "Demand Deeds",
            },
            {
              title: "Transactions",
            },
            {
              title: "Issues",
            },
            {
              title: "Broker",
            },
          ]}
        />
      </StandardAppContainerRounded>
      <StandardAppContainerRounded>
        {tab === "Table" && (
          <Box
            sx={{
              display: "flex",
              padding: "10px",
              width: "100%",
            }}
          >
            {tableLoading ? (
              <Box
                sx={{
                  width: "100%",
                  height: "40vh",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress size={40} />
              </Box>
            ) : !paymentComponents.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "20vh",
                }}
              >
                <Typography color={"GrayText"}>
                  Payment table not found
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <PaymentTableView data={booking?.paymentDetails} />
              </Box>
            )}
          </Box>
        )}
        {tab === "Demand Deeds" && <DemandDeedsList />}
        {tab === "Broker" && (
          <BookingBroker
            brokers={booking.brokerProfile}
            bookingId={booking._id}
            onSubmit={() => {
              getBooking();
            }}
          />
        )}
        {tab === "Transactions" && (
          <TransactionsList
            bookingId={booking?._id}
            profileId={booking?.project?.profile?._id}
            booking={booking}
          />
        )}
        {tab === "Issues" && <IssuesList bookingId={booking?._id} />}
      </StandardAppContainerRounded>
      <ConfirmationDialog
        open={openConformationDialog}
        setOpen={setOpenConformationDialog}
        cancelButtonText={"Cancel"}
        cancelListener={() => {
          setOpenConformationDialog(false);
        }}
        successButtonText={"Confirm"}
        title={"Are you sure?"}
        successListener={handleDeleteBooking}
        message={"This action cannot revert back"}
      />
    </StandardContainer>
  );
};

export default BookingView;

const StatusChanger = ({ currentStatus, bookingId, fetchBooking }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const allStatuses = ["Paid", "Cancelled", "Confirmed"];
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleClick = async (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusSelect = (newStatus) => {
    setSelectedStatus(newStatus);
    handleClose();
    setOpenConfirmDialog(true);
  };

  const handleConfirm = async () => {
    setLoading(true);
    const payload = {
      bookingId: bookingId,
      bookingData: {
        status: "Paid",
      },
    };
    const data = await Api.post("/listing/booking/get-by-id", payload);

    if (data) {
      console.log("data", data);
      fetchBooking();
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: `Status of booking changed from ${currentStatus} to ${selectedStatus}?`,
        },
      });
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
    setLoading(false);
    setOpenConfirmDialog(false);
  };

  const handleCancel = () => {
    setOpenConfirmDialog(false);
    setSelectedStatus(null);
  };

  return (
    <div>
      <Tooltip title="Change status">
        <IconButton onClick={handleClick} size="medium" color="primary">
          <MoreVert />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {allStatuses.map((status) => (
          <MenuItem key={status} onClick={() => handleStatusSelect(status)}>
            Mark as {status}
          </MenuItem>
        ))}
      </Menu>

      <Dialog
        open={openConfirmDialog}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Status Change"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to change the status of booking from{" "}
            {currentStatus} to {selectedStatus}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            onClick={handleConfirm}
            color="primary"
            variant="contained"
            autoFocus
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};
