import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableContainer from "../../styled/generic/TableContainer";
import Api from "../../../helpers/Api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import StatusChip from "../../styled/generic/StatusChip";
import { getWalletDataByProfileId } from "../../finance2o/accounts/api.call";
import { Add } from "@material-ui/icons";
import Button from "../../styled/generic/Button";
import { useDispatch, useSelector } from "react-redux";
import CreateTransactionModal from "./CreateTransactionModal";
function TransactionsList({ bookingId, profileId, booking }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showCreateTransactionModal, setShowCreateTransactionModal] =
    useState(false);
  const [transactions, setTransations] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [walletData, setWalletData] = useState({});

  console.log(walletData);

  useEffect(() => {
    if (profileId) {
      setPageLoading(true);
      getWalletDataByProfileId({ profileId }).then((data) => {
        if (data) {
          setWalletData(data);
        } else {
          dispatch({
            type: "AddApiAlert",
            payload: {
              success: false,
              message: "Failed to fetch wallet data",
            },
          });
        }
        setPageLoading(false);
      });
    }
  }, [profileId, dispatch]);

  useEffect(() => {
    const fetchTransations = async () => {
      const data = await Api.post(
        `listing/booking/transaction/get/${bookingId}`
      );
      if (data) {
        setTransations(data);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred 2",
          },
        });
      }
      setPageLoading(false);
    };
    fetchTransations();
  }, [bookingId, dispatch]);

  return (
    <Box
      sx={{
        paddingY: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <Typography variant="h5">Transations</Typography>
        <Button
          startIcon={<Add />}
          variant="outlined"
          onClick={() => setShowCreateTransactionModal(true)}
        >
          Add Transaction
        </Button>
      </Box>
      <Box>
        {pageLoading ? (
          <Box
            sx={{
              width: "100%",
              height: "40vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={30} />
          </Box>
        ) : !transactions.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "20vh",
            }}
          >
            <Typography color={"GrayText"}>No Transactions Found</Typography>
          </Box>
        ) : (
          <TableContainer
            loading={pageLoading}
            columns={[
              "Subject",
              "Status",
              "Aggrement value",
              "Payment due",
              "Amount paid",
              "Amount payable",
            ]}
            data={transactions}
          >
            {transactions.map((transaction, index) => (
              <tr key={index}>
                <td>
                  <Typography
                    onClick={() => {
                      history.push(
                        `/booking/transaction/view/${transaction._id}`
                      );
                    }}
                    variant="body1"
                    sx={{
                      fontWeight: "500",
                      "&:hover": {
                        cursor: "pointer",
                        textDecoration: "underline",
                      },
                    }}
                  >
                    {transaction.subject}
                  </Typography>
                </td>
                <td>
                  <StatusChip
                    label={transaction.status}
                    rounded={true}
                    variant={
                      transaction.status === "Draft" ? "Warning" : "Success"
                    }
                  />
                </td>
                <td>
                  <Typography>{transaction.aggrementValue}</Typography>
                </td>
                <td>
                  <Typography>{transaction.paymentDue}</Typography>
                </td>
                <td>
                  <Typography>{transaction.amountPaid}</Typography>
                </td>
                <td>
                  <Typography>{transaction.amountPayable}</Typography>
                </td>
              </tr>
            ))}
          </TableContainer>
        )}
      </Box>
      <CreateTransactionModal
        isOpen={showCreateTransactionModal}
        onClose={() => {
          setShowCreateTransactionModal(false);
        }}
        initialData={{
          booking: booking,
        }}
        libraryId={walletData?.defaultLibrary}
      />
    </Box>
  );
}

export default TransactionsList;
