// Updated CreateTransactionTable component
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import TableContainer from "../../styled/generic/TableContainer";
import { Add, Delete } from "@mui/icons-material";

const CreateTransactionTable = ({
  paymentDetails,
  billItems,
  setBillItems,
  status = "Draft",
  usedComponents = [],
}) => {
  const [availableChargeTypes, setAvailableChargeTypes] = useState([]);
  const [isAddingNewCell, setIsAddingNewCell] = useState(false);
  const [newCellData, setNewCellData] = useState({
    chargeType: "",
    chargeAmount: 0,
    currentDue: 0,
    penaltyDue: 0,
    rebateAmount: 0,
    totalAmountReceivable: 0,
    receivedAgainstCurrentDue: 0,
  });

  const handleInputChange = (index, field, value) => {
    const updatedData = [...billItems];
    updatedData[index][field] = field === "chargeType" ? value : Number(value);

    if (field === "chargeType") {
      const selectedDetail = paymentDetails?.find(
        (detail) => detail.title === value
      );
      if (selectedDetail) {
        updatedData[index].chargeAmount = selectedDetail.chargeAmount;
        updatedData[index].currentDue = selectedDetail.currentDue;
        updatedData[index].componentRef = selectedDetail.componentRef;
        updatedData[index].detail = selectedDetail._id;
      }
    }

    updatedData[index].receivedAgainstCurrentDue =
      updatedData[index].currentDue;

    updatedData[index].totalAmountReceivable =
      updatedData[index].currentDue +
      updatedData[index].penaltyDue -
      updatedData[index].rebateAmount;

    setBillItems(updatedData);
  };

  const saveNewCell = () => {
    const updatedData = [
      ...billItems,
      {
        ...newCellData,
        totalAmountReceivable:
          newCellData.currentDue +
          newCellData.penaltyDue -
          newCellData.rebateAmount,
      },
    ];
    setBillItems(updatedData);
    setIsAddingNewCell(false);
    setNewCellData({
      chargeType: "",
      chargeAmount: 0,
      currentDue: 0,
      penaltyDue: 0,
      rebateAmount: 0,
      totalAmountReceivable: 0,
      receivedAgainstCurrentDue: 0,
    });
  };

  const addNewCell = () => {
    const newCell = {
      chargeType: "",
      chargeAmount: 0,
      currentDue: 0,
      penaltyDue: 0,
      rebateAmount: 0,
      totalAmountReceivable: 0,
      receivedAgainstCurrentDue: 0,
    };

    setBillItems([...billItems, newCell]);
  };

  useEffect(() => {
    const usedTitles = billItems?.map((data) => data?.chargeType);
    if (usedComponents.length > 0) {
      const availableTitles = paymentDetails
        .filter(
          (item) => usedComponents.includes(item?.componentRef?._id) === false
        )
        ?.filter(
          (item) =>
            !usedTitles.includes(item.title) &&
            item?.componentRef?.hideInTable === false
        )
        ?.map((item) => item.title);
      setAvailableChargeTypes(availableTitles);
    } else {
      const availableTitles = paymentDetails
        ?.filter(
          (item) =>
            !usedTitles.includes(item.title) &&
            item?.componentRef?.hideInTable === false
        )
        ?.map((item) => item.title);
      setAvailableChargeTypes(availableTitles);
    }
  }, [billItems]);

  const removeRow = (index) => {
    const updatedData = billItems?.filter((_, i) => i !== index);
    setBillItems(updatedData);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
      }}
    >
      <TableContainer
        columns={[
          "Charge Type",
          "Charge Amount",
          "Current Due",
          "Penalty Amount",
          "Discount Amount",
          "Total Payable Amount",
          "Action",
        ]}
        containerHeight={""}
        data={billItems}
      >
        {billItems?.map((row, index) => (
          <tr key={index}>
            <td>
              <Select
                value={row?.chargeType}
                onChange={(e) =>
                  handleInputChange(index, "chargeType", e.target.value)
                }
                disabled={status !== "Draft"}
                fullWidth
              >
                {availableChargeTypes?.map((type, idx) => (
                  <MenuItem key={idx} value={type}>
                    {type}
                  </MenuItem>
                ))}
                {paymentDetails?.map((item, idx) => (
                  <MenuItem
                    key={idx}
                    value={item?.title}
                    sx={{
                      display: "none",
                    }}
                  >
                    {item?.title}
                  </MenuItem>
                ))}
              </Select>
            </td>
            <td>
              <TextField
                disabled={true}
                value={row.chargeAmount}
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
              />
            </td>
            <td>
              <TextField
                disabled={true}
                value={row.currentDue}
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
              />
            </td>
            <td>
              <TextField
                disabled={status !== "Draft"}
                value={row.penaltyDue}
                type="number"
                onChange={(e) =>
                  handleInputChange(index, "penaltyDue", e.target.value)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
              />
            </td>
            <td>
              <TextField
                disabled={status !== "Draft"}
                value={row.rebateAmount}
                type="number"
                onChange={(e) =>
                  handleInputChange(index, "rebateAmount", e.target.value)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
              />
            </td>
            <td>
              <TextField
                disabled={true}
                value={row.totalAmountReceivable}
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
              />
            </td>
            <td>
              <IconButton
                onClick={() => removeRow(index)}
                disabled={status !== "Draft"}
              >
                <Tooltip title="Delete" placement="bottom" arrow>
                  <Delete />
                </Tooltip>
              </IconButton>
            </td>
          </tr>
        ))}
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "start" }}>
        <Button
          variant={"outlined"}
          color={"primary"}
          size="large"
          onClick={addNewCell}
          startIcon={<Add />}
          disabled={status !== "Draft"}
        >
          New Cell
        </Button>
      </Box>
    </Box>
  );
};
export default CreateTransactionTable;
