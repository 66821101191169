import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Autocomplete,
  Avatar,
  Box,
  IconButton,
  MenuItem,
  Select,
  Divider,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";
import { Close, AccountBalance } from "@material-ui/icons";

import DrawerContainer from "../../styled/generic/DrawerContainer";
import FormBox from "../../styled/generic/FormBox";
import SpaceBetween from "../../styled/generic/SpaceBetween";
import BankAccountPicker from "../../styled/generic/BankAccountPickerDropdown";
import CreateTransactionTable from "../transaction/CreateTranscationTable";
import AttachmentsHandler from "../../styled/CommonComponents/Attachments.Handler";
import DuoButtonGroup from "../../styled/generic/DuoButtonGroup";
import ConfirmationDialog from "../../global/ConfirmationDialog";

import Api from "../../../helpers/Api";
import { allStatusOptions } from "../../../helpers/allStatuses";
import { getProfileDataPopulate } from "../../team/procurements/api.call";
import { getProfileByWallet } from "../../finance2o/commonComponent/transaction/api";

const initialFormState = {
  subject: "",
  selectedBooking: null,
  selectedBankAccount: null,
  paymentStatus: "Draft",
  transactions: [],
  attachedFiles: [],
  selectedBillingAccount: null,
  bankAccountId: null,
  billItems: [],
};

function CreateTransactionModal({
  isOpen,
  onClose,
  initialData = {},
  libraryId,
}) {
  const { profileId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);

  const [formData, setFormData] = useState(initialFormState);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedDialogMessage, setSelectedDialogMessage] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [billingAccounts, setBillingAccounts] = useState([]);
  const [selectedBillingAccounts, setSelectedBillingAccounts] = useState(null);
  const [billItems, setBillItems] = useState([]);
  const [profile, setProfile] = useState(null);
  const [useDefaultFinancialDetails, setUseDefaultFinancialDetails] =
    useState(false);
  const [paymentDetails, setPaymentDetails] = useState([]);

  console.log("selectedBooking", formData?.selectedBooking);
  console.log("formData", formData);

  useEffect(() => {
    // selectedBooking;
    if (formData.selectedBooking) {
      if (
        !formData.selectedBooking.billingAccount ||
        !formData?.selectedBooking.billingAccount?.bankAccount?._id
      ) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,

            message: "No billing account found",
          },
        });
        console.log("No billing account found");
        return;
      } else {
        setUseDefaultFinancialDetails(true);
        setFormData({
          ...formData,
          selectedBankAccount:
            formData.selectedBooking.billingAccount.bankAccount?._id,
          selectedBillingAccount: formData.selectedBooking.billingAccount?._id,
        });
      }
    }
  }, [formData.selectedBooking]);

  const PAYMENT_STATUS_OPTIONS = [
    "Draft",
    "Checking",
    "Confirmed",
    "In Progress",
    "Cancelled",
    "Paid",
    "Sent",
  ];

  const fetchBillingAccounts = async () => {
    const response = await Api.post(
      `wallet/billing/billing-account/sharedIds/get`,
      {
        sharedIds: [profile?._id],
      }
    );
    if (response) {
      setBillingAccounts(response.billingAccounts);
    } else {
      console.log("Error fetching billing accounts");
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error fetching billing accounts",
        },
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (profile && isOpen) {
      fetchBillingAccounts();
    }
  }, [profile, isOpen]);

  useEffect(() => {
    const fetchData = async () => {
      let data = await getProfileDataPopulate({
        profileId: profileId,
      });

      data = await getProfileByWallet({
        walletId: data.parent.wallet._id,
      });
      data = await getProfileDataPopulate({
        profileId: data,
      });
      if (data) {
        setProfile(data);
      } else {
        console.log("Error fetching profile data populate");
      }
    };
    if (profileId) {
      fetchData();
    }
  }, [profileId]);

  useEffect(() => {
    if (isOpen) {
      if (initialData?._id) {
        setIsEdit(true);
        setFormData({
          ...initialFormState,
          ...initialData,
          paymentStatus: initialData?.status ? initialData?.status : "Draft",
          selectedBooking: initialData?.booking,
          selectedBankAccount: initialData?.bankAccountId?._id,
          initialBankAccount: initialData?.bankAccountId,
          transactions: initialData?.billItems || [],
          attachedFiles: initialData?.attachedFiles || [],
        });
        setTransactions(initialData.billItems || []);
      } else {
        setIsEdit(false);
        setFormData({
          ...initialFormState,
          selectedBooking: initialData?.booking ?? null,
          transactions: [],
        });
      }
    }
  }, [isOpen, initialData]);

  useEffect(() => {
    fetchBookings();
  }, [libraryId]);

  useEffect(() => {
    if (formData.selectedBooking && isOpen) {
      setPaymentDetails(formData.selectedBooking.paymentDetails || []);
    }
  }, [formData.selectedBooking, isOpen]);

  const fetchBookings = async () => {
    try {
      const data = await Api.post("/listing/booking/get", { libraryId });
      if (data) {
        setBookings(data?.bookings);
      }
    } catch (error) {
      console.error("Error fetching bookings:", error);
    }
  };

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  useEffect(() => {
    setBillItems([]);
  }, [formData.selectedBooking]);

  const saveTransaction = async () => {
    setLoading(true);
    try {
      const transactionData = {
        subject: formData.subject,
        booking: formData.selectedBooking,
        bankAccountId: useDefaultFinancialDetails
          ? formData?.selectedBooking?.bankAccountId
          : formData.selectedBankAccount,
        billingAccount: useDefaultFinancialDetails
          ? formData?.selectedBooking?.billingAccount
          : formData?.selectedBillingAccount?._id,
        status: formData.paymentStatus,
        paymentDue: transactions.reduce(
          (acc, t) => acc + parseInt(t.currentDue || 0),
          0
        ),
        amountPaid: transactions.reduce(
          (acc, t) => acc + parseInt(t.receivedAgainstCurrentDue || 0),
          0
        ),
        amountPayable: transactions.reduce(
          (acc, t) => acc + parseInt(t.totalAmountReceivable || 0),
          0
        ),
        attachedFiles: formData.attachedFiles.map((x) => x?._id || ""),
        profile: user?.profile,
      };

      const endpoint = isEdit
        ? transactionData.status === "Paid"
          ? `/listing/booking/transaction/paid/${initialData._id}`
          : "/listing/booking/transaction/update"
        : "/listing/booking/transaction/create";
      const payload = isEdit
        ? {
            transactionId: initialData._id,
            data: { ...initialData, ...transactionData },
            billItems: billItems,
          }
        : { transactionData, billItems: billItems };

      const response = await Api.post(endpoint, payload);

      if (response?.data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: `Transaction ${
              isEdit ? "updated" : "created"
            } successfully`,
          },
        });
        onClose();
      }
    } catch (error) {
      console.error("Error saving transaction:", error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message:
            error.message || "An error occurred while saving the transaction",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSave = () => {
    if (formData.paymentStatus === "Sent") {
      setSelectedDialogMessage(0);
      setDialogOpen(true);
      return;
    }
    saveTransaction();
  };

  const confirmationDialogContent = [
    {
      title: "Confirm Status Change to Sent",
      message:
        "Are you sure you want to set the status to Sent? Once confirmed, the transactions will become uneditable, and this action cannot be undone.",
      confirmFn: saveTransaction,
      cancelFn: () => setDialogOpen(false),
    },
  ];

  return (
    <DrawerContainer
      title={isEdit ? "Edit Transaction" : "Create Transaction"}
      open={isOpen}
      setOpen={onClose}
      width="100%"
      childrenButton={
        <Select
          value={formData.paymentStatus}
          defaultValue={initialData?.status}
          disabled={initialData?.status === "Paid"}
          onChange={(e) => handleInputChange("paymentStatus", e.target.value)}
          sx={{ borderRadius: "8px", width: "200px" }}
        >
          {allStatusOptions["Booking Transactions"][
            initialData?.status || "Draft"
          ].map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
          {PAYMENT_STATUS_OPTIONS.map((item) => (
            <MenuItem
              sx={{
                display: "none",
              }}
              key={item}
              value={item}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
      }
    >
      <Box sx={{ borderRadius: 2 }}>
        <Typography
          variant="h5"
          color="primary"
          sx={{
            mb: 2,
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          Basic Information
        </Typography>
        <FormBox label="Subject" sx={{ flex: "3" }}>
          <TextField
            placeholder="Enter subject"
            variant="outlined"
            value={formData.subject}
            onChange={(e) => handleInputChange("subject", e.target.value)}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": { borderRadius: "10px" },
            }}
            fullWidth
          />
        </FormBox>

        <SpaceBetween
          left={
            <FormBox label="Select Booking" sx={{ flex: "3" }}>
              {formData.selectedBooking ? (
                <MenuItem
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "1px solid gray",
                    borderRadius: "10px",
                  }}
                >
                  <Box
                    sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                  >
                    <Avatar
                      src={
                        formData.selectedBooking?.customerProfile?.parent
                          ?.displayPicture?.url
                      }
                    />
                    <Typography>
                      {
                        formData.selectedBooking?.customerProfile?.parent
                          ?.displayName
                      }{" "}
                      {formData.selectedBooking?.unit?.project?.displayName}
                    </Typography>
                  </Box>
                  <IconButton
                    onClick={() => handleInputChange("selectedBooking", null)}
                  >
                    <Close />
                  </IconButton>
                </MenuItem>
              ) : (
                <Autocomplete
                  disabled={isEdit}
                  options={bookings.filter(
                    (booking) => booking?.customerProfile
                  )}
                  getOptionLabel={(option) =>
                    `${option?.customerProfile?.parent?.displayName} ${option?.unit?.project?.displayName}`
                  }
                  onChange={(_, newValue) =>
                    handleInputChange("selectedBooking", newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Booking"
                      variant="outlined"
                    />
                  )}
                  renderOption={(props, option) => (
                    <MenuItem {...props}>
                      <Avatar
                        src={
                          option?.customerProfile?.parent?.displayPicture?.url
                        }
                      />
                      <Typography>
                        {option?.customerProfile?.parent?.displayName}{" "}
                        {option?.unit?.project?.displayName}
                      </Typography>
                    </MenuItem>
                  )}
                />
              )}
            </FormBox>
          }
        />
      </Box>
      <Divider
        sx={{
          my: "1.5rem",
        }}
      />
      <Box sx={{ borderRadius: 2 }}>
        <Typography
          variant="h5"
          color="primary"
          sx={{
            mb: 2,
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          Financial Information
        </Typography>

        <Box
          sx={{
            marginY: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
            }}
          >
            <Checkbox
              checked={useDefaultFinancialDetails}
              onChange={(e) => setUseDefaultFinancialDetails(e.target.checked)}
            />
            <Typography>Use booking financial details</Typography>
          </Box>
        </Box>

        {!useDefaultFinancialDetails && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <FormBox label={"Billing account"}>
              <Select
                fullWidth
                onChange={(e) => {
                  const newBil = billingAccounts.find(
                    (item) => item._id === e.target.value
                  );
                  handleInputChange("selectedBillingAccount", newBil);
                  setSelectedBillingAccounts(newBil);
                }}
              >
                {billingAccounts.map((item) => (
                  <MenuItem key={item._id} value={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormBox>
            {formData.initialBankAccount ? (
              <FormBox label={"Selected Bank"}>
                <MenuItem
                  sx={{
                    width: "fit-content",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "15px",
                    border: "1px solid gray",
                    borderRadius: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <AccountBalance />
                    <Box>
                      <Typography variant="h6">
                        {formData?.initialBankAccount?.bankName}
                      </Typography>
                      <Typography variant="body2">
                        {formData?.initialBankAccount?.accountNumber}
                      </Typography>
                    </Box>
                  </Box>
                  <IconButton
                    onClick={() =>
                      handleInputChange("initialBankAccount", null)
                    }
                  >
                    <Close />
                  </IconButton>
                </MenuItem>
              </FormBox>
            ) : (
              <FormBox label="Select Bank">
                <BankAccountPicker
                  selectedBankAccount={formData.selectedBankAccount}
                  setSelectedBankAccount={(value) =>
                    handleInputChange("selectedBankAccount", value)
                  }
                />
              </FormBox>
            )}
          </Box>
        )}
      </Box>

      <Divider
        sx={{
          my: "1.5rem",
        }}
      />

      <Box sx={{ borderRadius: 2 }}>
        <Typography
          variant="h5"
          color="primary"
          sx={{
            mb: 2,
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          BillItems
        </Typography>
        <CreateTransactionTable
          paymentDetails={paymentDetails}
          status={initialData?.status || "Draft"}
          setTransactions={setTransactions}
          billItems={billItems}
          setBillItems={setBillItems}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          marginTop: "15px",
        }}
      >
        <AttachmentsHandler
          parentData={formData}
          setParentData={setFormData}
          parentModel={"BookingPaymentTransaction"}
          needToMakeDisable={false}
          setLoadingBool={setLoading}
        />
      </Box>

      <DuoButtonGroup
        disablePrimaryButton={initialData?.status === "Paid"}
        primaryButtonText="Save"
        primaryButtonListener={handleSave}
        hideSecondary={true}
        loadingPrimary={loading}
      />

      <ConfirmationDialog
        cancelButtonText={"Cancel"}
        cancelListener={
          confirmationDialogContent[selectedDialogMessage].cancelFn
        }
        successButtonText={"Confirm"}
        successListener={
          confirmationDialogContent[selectedDialogMessage].confirmFn
        }
        open={dialogOpen}
        setOpen={setDialogOpen}
        message={confirmationDialogContent[selectedDialogMessage].message}
        title={confirmationDialogContent[selectedDialogMessage].title}
      />
    </DrawerContainer>
  );
}

export default CreateTransactionModal;
