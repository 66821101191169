import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useStartTyping } from "react-use";

const calculateTotals = (data) => {
  return data?.reduce((acc, row) => {
    Object.keys(row).forEach((key) => {
      if (key !== "title") {
        acc[key] = (acc[key] || 0) + row[key];
      }
    });
    return acc;
  }, {});
};

export const PaymentTableView = ({ data }) => {
  console.log(data);
  const [totals, setTotals] = useState([]);

  useEffect(() => {
    if (data) {
      setTotals(calculateTotals(data));
    }
  }, [data]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Charge Type</TableCell>
            <TableCell align="right">Charge Amount</TableCell>
            <TableCell align="right">Current Due</TableCell>
            <TableCell align="right">Discount Amount</TableCell>
            <TableCell align="right">Delayed Interest</TableCell>
            <TableCell align="right">Receivable Due</TableCell>
            <TableCell align="right">Total Payable Amount</TableCell>
            <TableCell align="right">Received Amount</TableCell>
            <TableCell align="right">Receivable Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            ?.filter((item) => !item?.componentRef?.hideInTable)
            .map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row.title}
                </TableCell>
                <TableCell align="right">₹{row.chargeAmount}</TableCell>
                <TableCell align="right">₹{row.currentDue}</TableCell>
                <TableCell align="right">₹{row.rebateAmount}</TableCell>
                <TableCell align="right">₹{row.penaltyDue}</TableCell>
                <TableCell align="right">₹{row.dueReceivable}</TableCell>
                <TableCell align="right">₹{row.netReceivable}</TableCell>
                <TableCell align="right">₹{row.amountReceived}</TableCell>
                <TableCell align="right">₹{row.amountReceivable}</TableCell>
              </TableRow>
            ))}
          <TableRow>
            <TableCell component="th" scope="row">
              Total
            </TableCell>
            <TableCell align="right">₹{totals.chargeAmount}</TableCell>
            <TableCell align="right">₹{totals.currentDue}</TableCell>
            <TableCell align="right">₹{totals.rebateAmount}</TableCell>
            <TableCell align="right">₹{totals.penaltyDue}</TableCell>
            <TableCell align="right">₹{totals.dueReceivable}</TableCell>
            <TableCell align="right">₹{totals.netReceivable}</TableCell>
            <TableCell align="right">₹{totals.amountReceived}</TableCell>
            <TableCell align="right">₹{totals.amountReceivable}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
