import React, { useState, useEffect } from "react";
import DrawerContainer from "../../../styled/generic/DrawerContainer";
import { Checkbox, MenuItem, Select, TextField } from "@mui/material";
import { Box, Chip, Typography, Button, Avatar } from "@mui/material";
import { ContentCopy, Subject } from "@mui/icons-material";
import TransactionSection from "./TransactionTable";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { getWalletDataByProfileId } from "../../../finance2o/accounts/api.call";
import Api from "../../../../helpers/Api";
import { useDispatch } from "react-redux";
import DuoButtonGroup from "../../../styled/generic/DuoButtonGroup";
import FormBox from "../../../styled/generic/FormBox";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Divider, Paper, LinearProgress, InputAdornment } from "@mui/material";
import {
  CalendarToday,
  CheckCircle,
  Person,
  Event,
  ArrowForward,
} from "@mui/icons-material";
import { Info, Payment, Timeline } from "@mui/icons-material";
import { Add, ReceiptLong } from "@mui/icons-material";
import { allStatusOptions } from "../../../../helpers/allStatuses";
import { getProfileDataPopulate } from "../../../team/procurements/api.call";
import { getProfileByWallet } from "../../../finance2o/commonComponent/transaction/api";
import TermsAndConditionsPicker from "../../../team/procurements/TermsAndConditionsPicker";

const CreateDemandDeedDrawer = ({ setIsOpen, isOpen, width = "100%" }) => {
  const history = useHistory();
  const user = useSelector((state) => state.auth);
  const [walletData, setWalletData] = useState([]);
  const { profileId } = useParams();
  const dispatch = useDispatch();
  const [subject, setSubject] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("Draft");
  const [slab, setSlab] = useState({});
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([
    {
      transactionData: {
        booking: "",
        subject: "",
        bankAccountId: "",
        paymentStatus: "Draft",
        slabId: [],
        profile: user?.profile,
        paymentDue: 0,
        amountPaid: 0,
        amountPayable: 0,
      },
      billItems: [],
    },
  ]);

  const [selectedBooking, setSelectedBooking] = useState(null);
  const [bookings, setBookings] = useState([{}]);
  const [components, setComponents] = useState([{}]);
  const [billingAccounts, setBillingAccounts] = useState([]);
  const [selectedBillingAccounts, setSelectedBillingAccounts] = useState(null);
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  const [profile, setProfile] = useState(null);
  const [editorContent, setEditorContent] = useState("");
  const [selectedTermsAndConditions, setSelectedTermsAndConditions] = useState(
    []
  );

  const [useDefaultFinancialDetails, setUseDefaultFinancialDetails] =
    useState(true);

  const fetchBillingAccounts = async () => {
    const response = await Api.post(
      `wallet/billing/billing-account/sharedIds/get`,
      {
        sharedIds: [profile?._id],
      }
    );
    if (response) {
      setBillingAccounts(response.billingAccounts);
    } else {
      console.log("Error fetching billing accounts");
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error fetching billing accounts",
        },
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (profile && isOpen) {
      fetchBillingAccounts();
    }
  }, [profile]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = await getProfileDataPopulate({
          profileId: profileId,
        });

        console.log("data", data.parent.wallet._id);
        data = await getProfileByWallet({
          walletId: data.parent.wallet._id,
        });
        data = await getProfileDataPopulate({
          profileId: data,
        });
        setProfile(data);
        console.log("project", data);
      } catch (error) {
        console.log("Error fetching profile data populate", error);
      }
    };
    if (profileId) {
      fetchData();
    }
  }, [selectedBooking]);

  useEffect(() => {
    if (profileId) {
      getWalletDataByProfileId({ profileId: profileId })
        .then(async (data1) => {
          setWalletData(data1);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [profileId]);

  const getComponents = async (tableId) => {
    try {
      const { data } = await Api.post(
        "/listing/booking/payment/component/get",
        {
          tableId: tableId,
        }
      );
      if (data) {
        setComponents(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedBooking) {
      getComponents(selectedBooking?.paymentTable?._id);
    }
  }, [selectedBooking]);

  const getBookings = async () => {
    const { bookings } = await Api.post("/listing/booking/get", {
      libraryId: walletData?.defaultLibrary,
      projectIds: [profile?.parent?._id],
    });
    if (bookings) {
      setBookings(bookings);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred 2",
        },
      });
    }
  };

  useEffect(() => {
    if (walletData?.defaultLibrary) {
      getBookings();
    }
  }, [walletData]);

  const saveDemandDeed = async () => {
    setLoading(true);
    const transactionIds = [];
    for (let i = 0; i < transactions.length; i++) {
      let { data } = await Api.post("/listing/booking/transaction/create", {
        transactionData: {
          ...transactions[i].transactionData,
          status: paymentStatus,
          billingAccount: useDefaultFinancialDetails
            ? selectedBooking?.billingAccount
            : selectedBillingAccounts._id,
        },
        billItems: transactions[i].billItems,
      });
      if (data) {
        transactionIds.push(data._id);
      }
    }
    let data = await Api.post("/listing/booking/payment/demanddeed/create", {
      transactionIds: transactionIds,
      data: {
        paymentTableId: selectedBooking?.paymentTable?._id,
        termsAndConditions: editorContent,
        title: subject,
        bookingId: selectedBooking?._id,
        status: paymentStatus,
        profile: selectedBooking?.ownerProfile?._id,
        billingAccount: useDefaultFinancialDetails
          ? selectedBooking?.billingAccount
          : selectedBillingAccounts._id,
      },
    });
    if (data) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Demand deed created successfully",
        },
      });
      setLoading(false);
      history.push(`demanddeed/${data?.data?._id}`);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
      setLoading(false);
    }
  };

  console.log("selectedBooking", selectedBooking);

  const handleBookingSelect = (booking) => {
    setSelectedBooking(booking);
    const filteredSlabs = booking?.slabDetails.filter(
      (item) => item.isCompleted === true
    );
    console.log(
      "filteredSlabs",
      filteredSlabs.length > 0 ? filteredSlabs[filteredSlabs.length - 1] : {}
    );
    setSlab(
      filteredSlabs.length > 0 ? filteredSlabs[filteredSlabs.length - 1] : {}
    );
  };

  return (
    <DrawerContainer
      width={width}
      title="Create Demand Deed"
      open={isOpen}
      setOpen={setIsOpen}
    >
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          p: 3,
          bgcolor: "#f5f5f5",
        }}
      >
        <BasicInformationSection
          bookings={bookings}
          handleBookingSelect={handleBookingSelect}
          paymentStatus={paymentStatus}
          selectedBooking={selectedBooking}
          setPaymentStatus={setPaymentStatus}
          setSubject={setSubject}
          subject={subject}
        />

        {selectedBooking && (
          <Paper elevation={3} sx={{ p: 3, borderRadius: 2, mb: 3 }}>
            <Typography
              variant="h6"
              sx={{ mb: 2, fontWeight: "bold", color: "#1976d2" }}
            >
              Booking Details
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 3,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Avatar
                  src={
                    selectedBooking?.customerProfile?.parent?.displayPicture
                      ?.url
                  }
                  sx={{ width: 56, height: 56 }}
                />
                <Box>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {selectedBooking?.customerProfile?.parent?.displayName}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Customer
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Chip
                  label={selectedBooking?.bookingStatus || "Undefined"}
                  color="warning"
                  sx={{ mr: 1, fontWeight: "bold" }}
                />
                <Button
                  variant="outlined"
                  size="small"
                  endIcon={<ContentCopy />}
                  onClick={() => {
                    navigator.clipboard.writeText(selectedBooking?.inviteCode);
                    dispatch({
                      type: "AddApiAlert",
                      payload: {
                        success: true,
                        message: "Copied to clipboard",
                      },
                    });
                  }}
                  sx={{ textTransform: "none" }}
                >
                  Invite code: {selectedBooking?.inviteCode}
                </Button>
              </Box>
            </Box>
            <Divider sx={{ mb: 3 }} />
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                gap: 3,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <CalendarToday color="primary" />
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Registration Date
                  </Typography>
                  <Typography variant="body1">
                    {new Date(selectedBooking?.createdAt).toDateString()}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <CheckCircle
                  color={selectedBooking?.sanctioned ? "success" : "error"}
                />
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Sanctioned
                  </Typography>
                  <Typography variant="body1">
                    {selectedBooking?.sanctioned ? "Yes" : "No"}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Person color="primary" />
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Closing Manager
                  </Typography>
                  <Typography variant="body1">Undefined</Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Event color="primary" />
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Booking Date
                  </Typography>
                  <Typography variant="body1">
                    {new Date(selectedBooking?.bookingDate).toDateString()}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                endIcon={<ArrowForward />}
                onClick={() =>
                  history.push(`/booking/view/${selectedBooking._id}`)
                }
                sx={{ textTransform: "none" }}
              >
                View More Details
              </Button>
            </Box>
          </Paper>
        )}

        <Paper elevation={3} sx={{ p: 3, borderRadius: 2, mb: 3 }}>
          <Typography
            variant="h6"
            color="primary"
            sx={{
              mb: 3,
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            Financial Information
          </Typography>

          <Box
            sx={{
              marginY: "1rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
              }}
            >
              <Checkbox
                defaultChecked={useDefaultFinancialDetails}
                value={useDefaultFinancialDetails}
                onChange={(e) =>
                  setUseDefaultFinancialDetails(e.target.checked)
                }
              />
              <Typography>Use booking finacial details.</Typography>
            </Box>
          </Box>

          {!useDefaultFinancialDetails && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <FormBox
                label={"Billing account"}
                sx={{
                  widht: "50%",
                }}
              >
                <Select
                  fullWidth
                  onChange={(e) => {
                    const newBil = billingAccounts.find(
                      (item) => item._id === e.target.value
                    );
                    setSelectedBillingAccounts(newBil);
                  }}
                >
                  {billingAccounts.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormBox>
            </Box>
          )}
        </Paper>

        <Paper elevation={3} sx={{ p: 3, borderRadius: 2, mb: 3 }}>
          <Typography
            variant="h6"
            color="primary"
            sx={{
              mb: 3,
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            Terms and conditions
          </Typography>
          <TermsAndConditionsPicker
            editorContent={editorContent}
            selectedTermsAndConditions={selectedTermsAndConditions}
            setEditorContent={setEditorContent}
            setSelectedTermsAndConditions={setSelectedTermsAndConditions}
            libraryId={walletData?.defaultLibrary}
          />
        </Paper>

        {/* {selectedBooking && <SlabInfoSection slab={slab} />} */}

        <TransactionsSection
          bookings={bookings}
          components={components}
          selectedBooking={selectedBooking}
          setTransactions={setTransactions}
          transactions={transactions}
          user={user}
        />

        <Box sx={{ py: 1 }}>
          <DuoButtonGroup
            primaryButtonText="Save"
            primaryButtonListener={saveDemandDeed}
            hideSecondary={true}
            loadingPrimary={loading}
            loadingSecondary={loading}
          />
        </Box>
      </Box>
    </DrawerContainer>
  );
};

export default CreateDemandDeedDrawer;

export const SlabInfoSection = ({ slab }) => {
  const paymentPercentage = slab?.paymentPercentage || 0;
  const cumulativePercentage = slab?.cumulativePercentage || 0;

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        borderRadius: 2,
        mb: 3,
        background: "linear-gradient(145deg, #ffffff 0%, #f0f7ff 100%)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* <Info color="primary" sx={{ mr: 1 }} /> */}
          <Typography variant="h6" color="primary" fontWeight="bold">
            {slab?.title || "Slab 1"}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Payment color="success" sx={{ mr: 1 }} />
          <Typography variant="body1" mr={1}>
            Payment:
          </Typography>
          <Chip
            label={`${paymentPercentage}%`}
            color="success"
            variant="outlined"
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Timeline color="info" sx={{ mr: 1 }} />
          <Typography variant="body1" mr={1}>
            Cumulative:
          </Typography>
          <Chip
            label={`${cumulativePercentage}%`}
            color="info"
            variant="outlined"
          />
        </Box>
      </Box>

      <Box sx={{ mb: 1 }}>
        <Typography variant="body2" color="text.secondary" mb={1}>
          Overall Progress
        </Typography>
        <LinearProgress
          variant="determinate"
          value={cumulativePercentage}
          sx={{
            height: 10,
            borderRadius: 5,
            backgroundColor: "#e0e0e0",
            "& .MuiLinearProgress-bar": {
              borderRadius: 5,
              backgroundColor: "#2196f3",
            },
          }}
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
        <Typography variant="body2" color="text.secondary">
          0%
        </Typography>
        <Typography variant="body2" color="text.secondary">
          100%
        </Typography>
      </Box>
    </Paper>
  );
};

const TransactionsSection = ({
  transactions,
  setTransactions,
  selectedBooking,
  user,
}) => {
  const [usedComponents, setUsedComponents] = useState([]);

  useEffect(() => {
    const usedPaymentDetails = transactions.reduce((total, acc) => {
      return [
        ...total,
        ...acc.billItems.map((item) => item?.componentRef?._id),
      ];
    }, []);

    setUsedComponents(usedPaymentDetails);
  }, [transactions]);

  return (
    <Paper
      elevation={3}
      sx={{ p: 2, borderRadius: 2, bgcolor: "background.paper" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Typography
          variant="h6"
          color="primary"
          sx={{
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          {/* <ReceiptLong /> */}
          Transactions
        </Typography>
        <Button
          startIcon={<Add />}
          variant="contained"
          color="primary"
          onClick={() => {
            setTransactions((prev) => [
              ...prev,
              {
                transactionData: {
                  booking: selectedBooking._id,
                  subject: "",
                  bankAccountId: "",
                  paymentStatus: "Draft",
                  slabId: [],
                  profile: user?.profile,
                  paymentDue: 0,
                  amountPaid: 0,
                  amountPayable: 0,
                },
                billItems: [],
              },
            ]);
          }}
        >
          Add Transaction
        </Button>
      </Box>

      {transactions.map((transaction, index) => (
        <Box
          key={index}
          elevation={1}
          sx={{
            mb: 2,
            overflow: "hidden",
          }}
        >
          <Box sx={{ p: 0 }}>
            <TransactionSection
              paymentDetails={selectedBooking?.paymentDetails}
              initialData={transaction}
              index={index}
              setTransactions={setTransactions}
              usedComponents={usedComponents}
              bookingId={selectedBooking?._id}
            />
          </Box>
        </Box>
      ))}

      {transactions.length === 0 && (
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ textAlign: "center", mt: 2 }}
        >
          No transactions added yet. Click 'Add Transaction' to get started.
        </Typography>
      )}
    </Paper>
  );
};

const BasicInformationSection = ({
  subject,
  setSubject,
  paymentStatus,
  setPaymentStatus,
  selectedBooking,
  handleBookingSelect,
  bookings,
}) => {
  const PAYMENT_STATUS_OPTIONS = [
    "Draft",
    "Checking",
    "Confirmed",
    "In Progress",
    "Cancelled",
    "Paid",
  ];

  return (
    <Paper
      elevation={3}
      sx={{ p: 3, borderRadius: 2, bgcolor: "background.paper", mb: 3 }}
    >
      <Typography
        variant="h6"
        color="primary"
        sx={{
          mb: 3,
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        {/* <Subject /> */}
        Basic Information
      </Typography>
      <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
        <TextField
          label="Subject"
          placeholder="Enter subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          variant="outlined"
          fullWidth
          sx={{ flex: 4 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Subject color="action" />
              </InputAdornment>
            ),
          }}
        />

        <Select
          value={paymentStatus}
          onChange={(e) => setPaymentStatus(e.target.value)}
          fullWidth
          sx={{ flex: 1 }}
          startAdornment={<Payment color="action" sx={{ mr: 1, ml: -0.5 }} />}
          displayEmpty
          renderValue={(selected) => (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ mr: 1 }}>Status:</Typography>
              <Chip
                label={selected}
                size="small"
                color={selected === "PAID" ? "success" : "default"}
              />
            </Box>
          )}
        >
          {allStatusOptions["DemandDeed"]["Draft"].map((status) => (
            <MenuItem
              key={status}
              value={status}
              disabled={paymentStatus === status}
            >
              {status}
            </MenuItem>
          ))}
          {PAYMENT_STATUS_OPTIONS.map((item) => (
            <MenuItem
              sx={{
                display: "none",
              }}
              key={item}
              value={item}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Typography
        variant="subtitle1"
        sx={{
          mb: 1,
          fontWeight: "medium",
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Person color="action" />
        Select Booking
      </Typography>
      <Select
        value={selectedBooking}
        onChange={(e) => handleBookingSelect(e.target.value)}
        fullWidth
        sx={{
          "& .MuiSelect-select": {
            display: "flex",
            alignItems: "center",
            gap: 1,
          },
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
        }}
      >
        {bookings.map(
          (booking, index) =>
            booking?.customerProfile && (
              <MenuItem key={index} value={booking}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Avatar
                    src={booking?.customerProfile?.parent?.displayPicture?.url}
                    sx={{ width: 32, height: 32 }}
                  />
                  <Box>
                    <Typography variant="body1">
                      {booking?.customerProfile?.parent?.displayName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {booking?.unit?.project?.displayName}
                    </Typography>
                  </Box>
                </Box>
              </MenuItem>
            )
        )}
      </Select>
    </Paper>
  );
};
