import React, { useState, useEffect } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Button,
  Typography,
  IconButton,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  Grid,
  styled,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import UserSearcher from "../../styled/generic/UserSearcher";
import PaymentSection from "./PaymentSection";
import {
  Delete,
  LocationOn,
  Home,
  Apartment,
  Schedule,
  TableChart,
  Business,
} from "@mui/icons-material";
import { AccountManagerProfile } from "./bookingEditSections/AccountManagerProfile";
import { BrokerProfile } from "./bookingEditSections/BrokerProfile";
import { OwnerProfile } from "./bookingEditSections/OwnerProfile";
import { CustomerProfile } from "./bookingEditSections/CustomerProfile";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(1),
}));

const BookingForm = ({
  bookingData,
  onUpdateBooking,
  setBookingData,
  paymentTables,
  paymentSchedules,
  teams,
  billingAccounts,
}) => {
  const [formData, setFormData] = useState({
    project: null,
    projectBlock: "",
    unit: "",
    bookingStatus: "",
    sanctionedUnsanctioned: "",
    registrationDate: null,
    unitType: "",
    closingManager: "",
    bookingDate: null,
    team: "",
    selectedBankAccount: null,
    billingAccount: "",
    selectedTable: "",
    selectedSchedule: "",
    area: "",
    saleableArea: "",
    ratePerSqFt: "",
    customerProfile: null,
    owner: null,
    brokerProfile: [],
    accountManagers: [],
    isBrokerInvolved: false,
  });

  const [openDialog, setOpenDialog] = useState({
    customer: false,
    owner: false,
    broker: false,
    accountManager: false,
  });

  useEffect(() => {
    if (bookingData) {
      const paylaod = {
        project: bookingData.project,
        projectBlock: bookingData.projectBlock?._id || "",
        unit: bookingData.unit?._id || "",
        bookingStatus: bookingData.bookingStatus || "",
        sanctionedUnsanctioned: bookingData.sanctionedUnsanctioned
          ? "Sanctioned"
          : "Unsanctioned",
        registrationDate: dayjs(bookingData?.registrationDate),
        unitType: bookingData?.unitType || "",
        closingManager: bookingData?.closingManager?.parent || "",
        bookingDate: dayjs(bookingData?.bookingDate),
        team: bookingData?.team?._id || "",
        selectedBankAccount: bookingData?.bankAccountId || null,
        billingAccount: bookingData?.billingAccount?._id || "",
        selectedTable: bookingData?.paymentTable?._id || "",
        selectedSchedule: bookingData?.schedule?._id || "",
        area: bookingData?.area || "",
        saleableArea: bookingData?.saleableArea || "",
        ratePerSqFt: bookingData?.rsf || "",
        customerProfile: bookingData?.customerProfile || null,
        owner: bookingData?.ownerProfile?.parent || null,
        brokerProfile: bookingData?.brokerProfile || [],
        accountManagers:
          bookingData?.accountManagers.map((item) => item?.parent) || [],
        isBrokerInvolved: bookingData?.isBrokerInvolved || false,
      };

      console.log(paylaod, "paylaod");
      setFormData(paylaod);
    }
  }, [bookingData]);

  console.log("formData", formData);

  const handleChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdateBooking(formData);
  };

  const handleOpenDialog = (type) => {
    setOpenDialog((prev) => ({ ...prev, [type]: true }));
  };

  const handleCloseDialog = (type) => {
    setOpenDialog((prev) => ({ ...prev, [type]: false }));
  };

  const handleAddEntity = (type, entity) => {
    if (type === "broker") {
      setFormData((prev) => ({
        ...prev,
        brokers: [...prev.brokers, entity],
      }));
    } else if (type === "accountManager") {
      setFormData((prev) => ({
        ...prev,
        accountManagers: [...prev.accountManagers, entity],
      }));
    } else {
      handleChange(type, entity);
    }
    handleCloseDialog(type);
  };

  const handleRemoveEntity = (type, id) => {
    if (type === "broker") {
      setFormData((prev) => ({
        ...prev,
        brokerProfile: prev.brokers.filter((broker) => broker._id !== id),
      }));
    } else if (type === "accountManager") {
      setFormData((prev) => ({
        ...prev,
        accountManagers: prev.accountManagers.filter(
          (manager) => manager._id !== id
        ),
      }));
    } else {
      handleChange(type, null);
    }
  };

  const renderEntitySelection = (type) => (
    <Dialog open={openDialog[type]} onClose={() => handleCloseDialog(type)}>
      <DialogTitle>{`Select ${
        type.charAt(0).toUpperCase() + type.slice(1)
      }`}</DialogTitle>
      <DialogContent>
        {/* Implement your entity selection UI here */}
        <Typography>Entity selection UI goes here</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCloseDialog(type)}>Cancel</Button>
        <Button
          onClick={() =>
            handleAddEntity(type, { _id: "temp-id", displayName: "Temp Name" })
          }
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );

  let project = bookingData.project;
  let projectBlock = bookingData.projectBlock;
  let unit = bookingData.unit;
  let paymentTable = bookingData.paymentTable;
  let paymentSchedule = bookingData.schedule;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>
              Project Details
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="subtitle1"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Home fontSize="small" sx={{ mr: 1.5 }} />
                  {project?.displayName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="subtitle1"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <LocationOn fontSize="small" sx={{ mr: 1.5 }} />
                  {`${project?.address.city}, ${project?.address.country}`}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="subtitle1"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Apartment fontSize="small" sx={{ mr: 1.5 }} />
                  Project block: {projectBlock?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="subtitle1"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Business fontSize="small" sx={{ mr: 1.5 }} />
                  Project unit: {unit?.name}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  variant="subtitle1"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Schedule fontSize="small" sx={{ mr: 1.5 }} />
                  Project schedule: {paymentSchedule?.title || "N/A"}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  variant="subtitle1"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <TableChart fontSize="small" sx={{ mr: 1.5 }} />
                  Project payment: {paymentTable?.title || "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>
              Customer Information
            </Typography>
            <CustomerProfile
              formData={formData}
              givenProfileIdArr={[]}
              handleChange={handleChange}
              handleRemoveEntity={(type, id) => handleChange(type, null)}
            />
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>
              Owner Information
            </Typography>
            <OwnerProfile
              bookingData={bookingData}
              formData={formData}
              givenProfileIdArr={[]}
              handleChange={handleChange}
              handleRemoveEntity={(type, id) => handleChange(type, null)}
            />
          </StyledPaper>
        </Grid>

        <Grid item xs={12}>
          <StyledPaper
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              gap: 2,
            }}
          >
            <Grid
              sx={{
                flex: 1,
              }}
            >
              <Typography variant="h6" gutterBottom>
                Broker Information
              </Typography>
              <BrokerProfile
                formData={formData}
                handleChange={handleChange}
                handleOpenDialog={() =>
                  setOpenDialog({ ...openDialog, broker: true })
                }
              />
            </Grid>

            <Grid
              sx={{
                flex: 1,
              }}
            >
              <Typography variant="h6" gutterBottom>
                Account Managers
              </Typography>
              <AccountManagerProfile
                formData={formData}
                handleChange={handleChange}
                handleRemoveEntity={(id) => {
                  const updatedManagers = formData.accountManagers.filter(
                    (manager) => manager._id !== id
                  );
                  handleChange("accountManagers", updatedManagers);
                }}
                givenProfileIdArr={[]}
              />
            </Grid>
          </StyledPaper>
        </Grid>

        <Grid item xs={12}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>
              Booking Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Booking Status</InputLabel>
                  <Select
                    value={formData.bookingStatus}
                    onChange={(e) =>
                      handleChange("bookingStatus", e.target.value)
                    }
                  >
                    <MenuItem value="Pending">Pending</MenuItem>
                    <MenuItem value="Completed">Completed</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Sanctioned/Unsanctioned</InputLabel>
                  <Select
                    value={formData.sanctionedUnsanctioned}
                    onChange={(e) =>
                      handleChange("sanctionedUnsanctioned", e.target.value)
                    }
                  >
                    <MenuItem value="Sanctioned">Sanctioned</MenuItem>
                    <MenuItem value="Unsanctioned">Unsanctioned</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <DatePicker
                  label="Registration Date"
                  value={formData.registrationDate}
                  onChange={(date) => handleChange("registrationDate", date)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  sx={{
                    width: "100%",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Unit Type</InputLabel>
                  <Select
                    value={formData.unitType}
                    onChange={(e) => handleChange("unitType", e.target.value)}
                  >
                    <MenuItem value="1BHK">1BHK</MenuItem>
                    <MenuItem value="2BHK">2BHK</MenuItem>
                    <MenuItem value="3BHK">3BHK</MenuItem>
                    <MenuItem value="4BHK">4+BHK</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{
                      width: "fit-content",
                    }}
                  >
                    Closing Manager:
                  </Typography>
                  {formData?.closingManager ? (
                    <Box
                      sx={{
                        display: "flex",
                        padding: 1,
                        borderRadius: "10px",
                        gap: 2,
                        alignItems: "center",
                        justifyContent: "space-between",
                        border: "1px solid #e0e0e0",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        <Avatar
                          src={formData.closingManager.displayPicture?.url}
                          alt={formData.closingManager.displayName}
                        />
                        <Typography variant="body1">
                          {formData?.closingManager?.displayName}
                        </Typography>
                      </Box>
                      <IconButton
                        onClick={() => handleChange("closingManager", null)}
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  ) : (
                    <UserSearcher
                      onSelect={(val) => {
                        handleChange("closingManager", val);
                      }}
                      label="Closing Manager"
                    />
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <DatePicker
                  label="Booking Date"
                  value={formData.bookingDate}
                  onChange={(date) => handleChange("bookingDate", date)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  sx={{
                    width: "100%",
                  }}
                />
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>

        <Grid item xs={12}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>
              Financial Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Team</InputLabel>
                  <Select
                    value={formData.team}
                    onChange={(e) => handleChange("team", e.target.value)}
                  >
                    {teams
                      .filter((item) => item.title)
                      .map((team) => (
                        <MenuItem key={team._id} value={team._id}>
                          {team.title}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Billing Account</InputLabel>
                  <Select
                    value={formData.billingAccount}
                    onChange={(e) =>
                      handleChange("billingAccount", e.target.value)
                    }
                  >
                    {billingAccounts.map((account) => (
                      <MenuItem key={account._id} value={account._id}>
                        {account.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>

        {/* <Grid item xs={12}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom mb={1}>
              Area and Pricing
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Total Area (sqft)"
                  type="number"
                  value={formData.area}
                  onChange={(e) => handleChange("area", e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <House />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Total Saleable Area (sqft)"
                  type="number"
                  value={formData.saleableArea}
                  onChange={(e) => handleChange("saleableArea", e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <House />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Rate per Sq Ft"
                  type="number"
                  value={formData.ratePerSqFt}
                  onChange={(e) => handleChange("ratePerSqFt", e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyRupee />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid> */}

        <Grid
          item
          xs={12}
          sx={{
            overflowX: "scroll",
            pt: 0,
          }}
        >
          <StyledPaper
            sx={{
              pt: 0,
            }}
          >
            <PaymentSection
              bookingData={bookingData}
              setBookingData={setBookingData}
            />
          </StyledPaper>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 2,
            mt: 2,
          }}
        >
          <Button
            type="button"
            variant="outlined"
            size="medium"
            onClick={() => {
              // history.push("/booking/view/" + bookingData._id);
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="medium"
            onClick={handleSubmit}
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default BookingForm;

// organization?.profile?._id, project?.profile?._id
