import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableFooter,
  TableBody,
  Tooltip,
  TableContainer,
} from "@mui/material";
// import TableContainer from "../../styled/generic/TableContainer";
import Api from "../../../helpers/Api";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import evaluateMathExpression from "../../../helpers/evaluateMathExpression";
import { RefreshOutlined } from "@mui/icons-material";
import PaymentIcon from "@mui/icons-material/Payment";

const PaymentTable = ({
  components = [],
  displayOnly = false,
  paymentDetails: data,
  setPaymentDetails: setData,
  bookingId,
  tableLoading,
  slabs,
  area = 0,
}) => {
  const dispatch = useDispatch();
  console.log(data);
  const [loading, setLoading] = useState(false);
  const totalCompletePercentage = Number(
    // slabs[
    //   slabs?.filter((slab) => slab?.type === "aggrement" || slab?.isCompleted)
    //     ?.length - 1
    // ]?.cumulativePercentage || "0"
    0
  );

  const properties = [
    "chargeAmount",
    "currentDue",
    // "rebateAmount",
    "penaltyDue",
    "dueReceivable",
    "amountReceivable",
    "amountReceived",
    "netReceivable",
  ];

  const reCalculatePaymentTableComponents = async () => {
    const mongoComponents = data.map((detail) => ({
      _id: detail.componentRef._id,
      value: detail.chargeAmount || 0,
      title: detail.componentRef.title,
    }));

    let updatedData = data.map((detail) => ({ ...detail }));

    for (let i = 0; i < updatedData.length; i++) {
      let component = updatedData[i];
      console.log(component, " is the componentIn");
      if (
        component.componentRef.type === "Expression" &&
        component.componentRef.expression
      ) {
        // Replace component titles with their corresponding IDs in the expression
        let modifiedExpression = component.componentRef.expression;
        mongoComponents.forEach((comp) => {
          const regex = new RegExp(comp.title, "g");
          modifiedExpression = modifiedExpression.replace(regex, comp._id);
        });

        let value = evaluateMathExpression(modifiedExpression, mongoComponents);
        console.log(
          " component.componentRef.expression ",
          value,
          mongoComponents,
          component.componentRef.expression
        );
        if (isNaN(value)) {
          value = 0;
        }

        value = parseFloat(value).toFixed(2);

        // Update the mongoComponents array with the new calculated value
        const componentIndex = mongoComponents.findIndex(
          (c) => c._id === component.componentRef._id
        );
        if (componentIndex !== -1) {
          mongoComponents[componentIndex].value = Number(value);
        }

        // Update the component's chargeAmount
        updatedData[i] = {
          ...updatedData[i],
          chargeAmount: Number(value),
        };
      }

      // Recalculate other fields
      updatedData[i] = {
        ...updatedData[i],
        netReceivable:
          Number(updatedData[i].chargeAmount) +
          Number(updatedData[i].penaltyDue) -
          Number(updatedData[i].rebateAmount),
        amountReceivable:
          Number(updatedData[i].chargeAmount) +
          Number(updatedData[i].penaltyDue) -
          Number(updatedData[i].rebateAmount) -
          Number(updatedData[i].amountReceived),
        currentDue: updatedData[i].milestoneCalculation
          ? ((Number(updatedData[i].chargeAmount) -
              Number(updatedData[i].rebateAmount)) *
              totalCompletePercentage) /
            100
          : Number(updatedData[i].chargeAmount),
        dueReceivable:
          Number(updatedData[i].chargeAmount) -
          Number(updatedData[i].amountReceived),
        ratePerSqFt: area > 0 ? Number(updatedData[i].chargeAmount) / area : 0,
      };
    }

    setData(updatedData);
    console.log("recalculate ", updatedData);
  };

  console.log(data);

  const handleInputChange = (index, field, value) => {
    setData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        [field]: Number(value) ? Number(value) : value,
      };
      updatedData[index].netReceivable =
        Number(updatedData[index].chargeAmount) +
        Number(updatedData[index].penaltyDue) -
        Number(updatedData[index].rebateAmount);

      updatedData[index].amountReceivable =
        Number(updatedData[index].netReceivable) -
        Number(updatedData[index].amountReceived);

      updatedData[index].currentDue = updatedData[index].milestoneCalculation
        ? ((Number(updatedData[index].chargeAmount) -
            Number(updatedData[index].rebateAmount)) *
            totalCompletePercentage) /
          100
        : Number(updatedData[index].chargeAmount);

      updatedData[index].dueReceivable =
        updatedData[index].chargeAmount - updatedData[index].amountReceived;

      updatedData[index].ratePerSqFt = updatedData[index].chargeAmount / area;
      return updatedData;
    });
  };

  const calculateTotal = (property) => {
    const totalAmount = data
      .reduce((total, component) => {
        if (component?.componentRef?.hideInTable) return total;

        return total + parseFloat(component[property]);
      }, 0)
      .toFixed(2);
    return totalAmount || 0;
  };

  async function handleSubmit() {
    setLoading(true);
    const responseData = await Api.post(
      "listing/booking/payment/table/update",
      {
        data,
      }
    );
    if (responseData) {
      setData(responseData);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Something went wrong.",
        },
      });
    }

    setLoading(false);
    return;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Box
        sx={{
          padding: 2,
          paddingX: 0,
          borderRadius: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          Payment Table
        </Typography>
        <Tooltip title="Recalculate Payment Components">
          <IconButton
            color="primary"
            size="small"
            onClick={reCalculatePaymentTableComponents}
            sx={{
              backgroundColor: "white",
              "&:hover": {
                backgroundColor: "primary.main",
                color: "white",
              },
              transition: "all 0.3s",
            }}
          >
            <RefreshOutlined />
          </IconButton>
        </Tooltip>
      </Box>

      <TableContainer
        component={Paper}
        elevation={2}
        sx={{
          borderRadius: 2,
          overflowX: "auto",
          "&::-webkit-scrollbar": {
            height: 8,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "primary.light",
            borderRadius: 4,
          },
        }}
      >
        <Table stickyHeader aria-label="payment table" sx={{ minWidth: 1500 }}>
          {" "}
          {/* Increased minWidth */}
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "primary.main",
                  color: "white",
                  borderBottom: "none",
                  whiteSpace: "nowrap",
                  textTransform: "uppercase",
                  fontSize: "0.75rem",
                  letterSpacing: "0.5px",
                  width: "15%", // Slightly increased width for Charge Type
                  minWidth: 180, // Increased minimum width
                }}
              >
                Charge Type
              </TableCell>
              {[
                "Charge Amount",
                "Current Due",
                // "Discount Amount",
                "Interest",
                "Receivable Due",
                "Total Payable Amount",
                "Received Amount",
                "Receivable Amount",
              ].map((header) => (
                <TableCell
                  key={header}
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "primary.main",
                    color: "white",
                    borderBottom: "none",
                    whiteSpace: "nowrap",
                    textTransform: "uppercase",
                    fontSize: "0.75rem",
                    letterSpacing: "0.5px",
                    width: "10.625%", // Evenly distribute remaining width
                    minWidth: 150, // Increased minimum width
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((component, index) => {
              if (component?.componentRef?.hideInTable) return null;
              return (
                <TableRow
                  key={index}
                  sx={{
                    "&:nth-of-type(odd)": { backgroundColor: "grey.50" },
                    "&:hover": { backgroundColor: "primary.50" },
                    transition: "background-color 0.3s",
                  }}
                >
                  <TableCell sx={{ width: "15%", minWidth: 180 }}>
                    {component?.title}
                  </TableCell>
                  {properties.map((property) => (
                    <TableCell
                      key={property}
                      sx={{ width: "10.625%", minWidth: 150 }}
                    >
                      <TextField
                        disabled={
                          displayOnly ||
                          [
                            "currentDue",
                            "dueReceivable",
                            "netReceivable",
                            "amountReceivable",
                            "penaltyDue",
                          ].includes(property)
                        }
                        variant="outlined"
                        fullWidth
                        type="number"
                        value={component[property] || 0}
                        onChange={(e) =>
                          handleInputChange(index, property, e.target.value)
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                        size="small"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: 1,
                            backgroundColor: "background.paper",
                            "&:hover": {
                              borderColor: "primary.main",
                            },
                            "&.Mui-focused": {
                              borderColor: "primary.main",
                              boxShadow: (theme) =>
                                `0 0 0 2px ${theme.palette.primary.light}`,
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: "8px 12px",
                            textAlign: "right", // Align numbers to the right
                          },
                        }}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow sx={{ backgroundColor: "primary.50" }}>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  width: "15%",
                  minWidth: 180,
                }}
              >
                <Typography variant="body1" fontWeight={500}>
                  Total:
                </Typography>
              </TableCell>
              {properties.map((property) => (
                <TableCell
                  key={property}
                  sx={{ width: "10.625%", minWidth: 150 }}
                >
                  <TextField
                    disabled
                    value={calculateTotal(property)}
                    variant="outlined"
                    fullWidth
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                      readOnly: true,
                    }}
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 1,
                        backgroundColor: "white",
                        fontWeight: "bold",
                      },
                      "& .MuiOutlinedInput-input": {
                        color: "primary.dark",
                        fontWeight: "bold",
                        textAlign: "right", // Align numbers to the right
                      },
                    }}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {!displayOnly && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <LoadingButton
            variant="contained"
            size="medium"
            onClick={handleSubmit}
            loading={loading}
            sx={{
              fontWeight: "bold",
              backgroundColor: "primary.main",
              color: "white",
              "&:hover": {
                backgroundColor: "primary.dark",
              },
            }}
          >
            Save
          </LoadingButton>
        </Box>
      )}
    </Box>
  );
};

export default PaymentTable;
