import React, { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  Paper,
  Grid,
  Avatar,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Chip,
  InputAdornment,
} from "@mui/material";
import {
  ArrowBack,
  Print,
  Edit,
  AccountBalance,
  Apartment,
  Home,
  BookOnline,
  AttachMoney,
  ReceiptLong,
} from "@mui/icons-material";
import Api from "../../../helpers/Api";
import { useDispatch } from "react-redux";
import CreateTransactionTable from "./CreateTranscationTable";
import { allStatusOptions } from "../../../helpers/allStatuses";

const TransactionEditPage = () => {
  const { transactionId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [transaction, setTransaction] = useState({});
  const [loading, setLoading] = useState(true);
  const [billItems, setBillItems] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [booking, setBooking] = useState({});
  const [initialStatus, setInitialStatus] = useState("");
  const fetchTransaction = useCallback(async () => {
    try {
      const data = await Api.post(
        `/listing/booking/transaction/${transactionId}`
      );
      if (!data) {
        throw new Error("Failed to fetch transaction");
      }
      setPaymentDetails(data?.booking?.paymentDetails || []);
      setTransaction(data);
      setInitialStatus(data.status);
      setBillItems(data.billItems || []);
      setBooking(data.booking || {});
      setLoading(false);
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: error.message || "Failed to fetch transaction",
        },
      });
      setLoading(false);
    }
  }, [transactionId, dispatch]);

  useEffect(() => {
    if (transactionId) {
      fetchTransaction();
    } else {
      history.goBack();
    }
  }, [transactionId, history, fetchTransaction]);

  const handleInputChange = (field, value) => {
    setTransaction((prev) => ({ ...prev, [field]: value }));
  };

  const handleBillItemsChange = (newBillItems) => {
    setBillItems(newBillItems);
  };

  const handleSave = async () => {
    const paylaod = {
      transactionId: transactionId,
      data: { ...transaction },
      billItems,
    };

    const apiUrl =
      transaction.status === "Paid"
        ? `/listing/booking/transaction/paid/${transactionId}`
        : "/listing/booking/transaction/update";

    const data = await Api.post(apiUrl, paylaod);
    if (data) {
      console.log(data);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Transaction update successfully.",
        },
      });
      history.goBack();
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error while updating transactions.",
        },
      });
    }
  };

  const handlePrint = () => {
    console.log("Printing transaction");
    alert("Printing transaction...");
  };

  const isEditable = initialStatus === "Draft";

  console.log(transaction);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        Loading...
      </Box>
    );
  }

  return (
    <Box sx={{ minHeight: "100vh" }}>
      <AppBar
        position="static"
        sx={{ boxShadow: "none", backgroundColor: "Background" }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            onClick={() => history.goBack()}
            sx={{ mr: 2 }}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Edit Transaction
          </Typography>
          <Select
            value={transaction?.status || ""}
            onChange={(e) => handleInputChange("status", e.target.value)}
            size="small"
            sx={{ bgcolor: "white", minWidth: 120 }}
          >
            {allStatusOptions["Booking Transactions"][initialStatus].map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
            {["Draft", "Checking", "Sent", "Cancelled", "Paid"].map((item) => (
              <MenuItem
                value={item}
                sx={{
                  display: "none",
                }}
              >
                {item}
              </MenuItem>
            ))}
          </Select>
        </Toolbar>
      </AppBar>

      <Box sx={{ p: 3, margin: "0 auto" }}>
        <Paper sx={{ p: 3, mb: 3, boxShadow: "none", borderRadius: 3 }}>
          <Typography variant="h6" gutterBottom>
            Basic Information
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Subject"
                value={transaction?.subject || ""}
                onChange={(e) => handleInputChange("subject", e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6} md={3}>
              <InfoItem
                icon={
                  <Avatar
                    src={booking?.customerProfile?.parent?.displayPicture?.url}
                  />
                }
                label="Customer Name"
                value={booking?.customerProfile?.parent?.displayName}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InfoItem
                icon={<Apartment />}
                label="Project"
                value={booking?.projectBlock?.project?.displayName}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <InfoItem
                icon={<Home />}
                label="Block"
                value={booking?.projectBlock?.name}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InfoItem
                icon={<BookOnline />}
                label="Unit"
                value={booking?.unit?.name}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InfoItem
                icon={<AccountBalance />}
                label="Bank details"
                value={
                  <Box>
                    <Typography variant="body1" fontWeight="medium">
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Bank Name
                      </span>
                      :- {transaction?.bankAccountId?.bankName}
                    </Typography>
                    <Typography variant="body1" fontWeight="medium">
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Account Number
                      </span>{" "}
                      :- {transaction?.bankAccountId?.accountNumber}
                    </Typography>
                  </Box>
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InfoItem
                icon={<AttachMoney />}
                label="Amount"
                value={`₹ ${transaction.amountPayable || 0}`}
              />
            </Grid>
          </Grid>
        </Paper>

        <Paper sx={{ p: 3, boxShadow: "none", borderRadius: 3 }}>
          <Typography variant="h6" gutterBottom>
            Bill Items
          </Typography>
          {paymentDetails.length > 0 && (
            <CreateTransactionTable
              billItems={billItems}
              paymentDetails={paymentDetails}
              setBillItems={handleBillItemsChange}
              status={transaction.status || "Draft"}
            />
          )}
        </Paper>

        <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const InfoItem = ({ icon, label, value }) => (
  <Box sx={{ display: "flex", alignItems: "start", mb: 1 }}>
    <Box sx={{ mr: 1, color: "primary.main" }}>{icon}</Box>
    <Box>
      <Typography variant="body2" color="text.secondary">
        {label}
      </Typography>
      <Typography variant="body1" fontWeight="medium">
        {value}
      </Typography>
    </Box>
  </Box>
);

export default TransactionEditPage;
